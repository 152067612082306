.pedidos-agenda-intervalos-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-rows: repeat(auto-fill, 70px);
  gap: .5rem;
}

.agenda-month {
  border: 1px solid #ccc;
  border-radius: .3rem;
  font-weight: bold;
  padding: .5rem;
  cursor: pointer;
  transition: ease 100ms background;
}
.agenda-month:hover{
  background-color: #c8edc0bc;
}
.agenda-month.selected {
  background-color: #a7efa7;
}

.pedido-calendar-agenda, .agenda-intervalos {
  cursor: pointer;
  transition: ease 100ms background, color;
}
.pedido-calendar-agenda{
  color: #939393;
}
.pedido-calendar-agenda.current-month {
  background-color: #bcd2ffb7;
  color: #282828;
}
.pedido-calendar-agenda.no-intervals {
  background-color: #f0f0f0;
  color: #bababa;
}
.pedido-calendar-agenda.current-month.current-day {
  font-weight: bolder;
  font-size: 1.1rem;
}
.pedido-calendar-agenda.selected, .pedido-calendar-agenda.selected:hover {
  background-color: #6164bf;
  color: #fff;
}
.pedido-calendar-agenda:hover{
  background-color: #868aff86;
}

.agenda-intervalos.selected {
  background-color: #8eff86af;
}
.agenda-intervalos:hover{
  background-color: #8eff8686;
}

.agenda-intervalos {
  display: flex;
  flex-direction: column;
  border-radius: .5rem;
  border: 1px solid #aaa;
  padding: .5rem;
  text-align: center;
}

.agenda-intervalos-agendamientos-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: column;
}

.agenda-intervalos-agendamiento {
  display: flex;
  flex-direction: column;
  border-radius: .5rem;
  border: 1px solid #aaa;
  padding: .5rem;
}