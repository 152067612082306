:root {
   /* MARK: Colors */
   --body: #FCFCFF;
   
   --main-color: #2854F0;
   --runflat-tag-color: #e92b2b;
   --light-main-color: #699BF7;
   --soft-main-color: #f1f6ff;
   --dark-main-color: #001A70;
   --soft-dark-main-color: #1E357D;

   --secondary-color: #CC3E35;
   --light-secondary-color: #FF4036;
   --soft-secondary-color: #ffb8b8;
   --dark-secondary-color: #9C332C;

   --grey: #45535C;
   --light-grey: #718B9E;
   --lighter-grey: #dee1e4;
   --soft-grey: #F8F8F8;
   --input-grey: #E4E4E4;

   --background-color: #FFFFFF;
   
   --black: #3A3A3A;

   --yellow: #FFE601;

   --green: #00AD00;
   --dark-green: #078807;

   /* MARK: Fonts */
   --main-font: 'Rubik', sans-serif;

   /* MARK: Widths */
   --section-width: 92%;

   --section-max-width: 130rem;

   /* MARK: Z-indexes */
   --section-index: 1;
   --header-index: 5;
   --hud-index: 10;
   --spinner-index: 15;
   --modal-index: 20;
   --high-hud-index: 30;
   
   /* MARK: Shadows */
   --element-shadow-color: rgba(8, 13, 82, 0.2);
   --element-shadow: 0 0 0.3rem 0.1rem var(--element-shadow-color);
   --hud-shadow: 0 0 0.3rem 0.2rem rgba(0, 0, 0, 0.1);

   /* MARK: Borders */
   --input-border: 0.1rem solid rgba(0, 0, 0, 0.1);

   /* MARK: Margins */
   --section-margin: 2rem auto 0;
}

@media only screen and (min-width: 768px) {
   :root {
      /* MARK: Widths */
      --section-width: 85%;

      /* MARK: Margins */
      --section-margin: 10rem auto 0;
   }
}