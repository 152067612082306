@import 'scrollToTop.css';
@import 'tooltip.css';
@import 'zoom.css';
@import 'pedidos/detalle/index.css';
@import 'tables/index.css';
@import 'root.css';

*, *::after, *::before{
  box-sizing: border-box;
}

.diff > tbody > tr:nth-child(even) {
  background-color: #f5f5f5;
}

.diff > tbody > tr:nth-child(even).selected {
  background-color: #00fe372d;
}

.term-cond-info{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.fs-6{
  font-size: .9rem;
}

.fs-7{
  font-size: .8rem;
}

.fs-8{
  font-size: .7rem;
}

.tx{
  resize: none;
  width: 100%;
  padding: .5rem;
}

/* CUSTOM CSS EXTENDED */

.bg-product{
  background-color: #bbffcb;
}

.btn.active{
  background-color: #0d31d38c;
  color: #fff;
}

.left-0{
  left: 0;
}

.select2-container{
  max-height: 40vh;
}

/* DATATABLE */
.data-selector.selected, .data-selector.selected:hover{
  background-color: #00fe372d;
}

.data-selector{
  transition: ease 200ms background;
  cursor: pointer;
}

.data-selector:hover{
  background-color: rgba(0, 255, 128, 0.078);
}

.dataTable{
  max-height: 800px;
}

.dataTable > table > thead{
  width: 100%;
}
/* FIN DATATABLE */

/* ICON-PICKER */
.btn.icon-picker{
  transition: ease .2s background;
  background-color: #ffffff;
}

.btn.icon-picker.active{
  background-color: #3623ca73;
}

.btn.icon-picker:hover{
  background-color: #1c174043;
}

.icon-picker-container{
  max-height: 50vh;
}

.icon-picker-square, .icon-picker-square-mdi, .icon-picker-square-listed{
  border: 1px solid rgba(17, 17, 17, 0.449);
  display: inline-block;
  width: 32px;
  height: 32px;
  transition: ease .2s background;
}

.icon-picker-square > div{
  display: flex;
  height: 100%;
}

.icon-picker-square:hover, .icon-picker-square-mdi:hover{
  background-color: #4464f352;
  cursor: pointer;
}
.icon-picker-square.selected:hover{
  background-color: transparent;
  cursor: default;
}

.icon-picker-square > div > *{
  margin: auto;
}

.icon-picker-list{
  top: 100%;
  min-width: 300px;
  max-width: 600px;
}

.icon-picker-input{
  max-height: 4rem;
  min-height: 3.2857rem;
  border: 1px solid #d8d6de;
}

.icon-list{
  transition: ease background .2s;
}

.icon-list.active{
  background-color: #3623ca73;
}

.icon-list:hover{
  background-color: #04048018;
}

.icon-list.active:hover{
  background-color: #3623ca73;
}
/* FIN ICON-PICKER */


/* VISTA DETALLE (LISTA) */
.lista-detalle > img {
  max-height: 70px;
  width: auto;
}
/* FIN VISTA DETALLE (LISTA) */

.height-listado{
  max-height: 80px;
  max-width: 120px;
  object-fit: contain;
}

.input-date{
  max-width: 140px;
}

.multi-data > td p{
  margin-bottom: .2rem;
}

.bg-table-odd{
  background-color: #2600ff0b;
  color: rgb(0, 0, 0);
}

.overflow-y-auto{
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-x-auto{
  overflow-y: hidden;
  overflow-x: auto;
}

.overflow-x-scroll{
  overflow-y: hidden;
  overflow-x: scroll;
}


.sub-text-4{
  font-size: .7rem;
}

.sub-text-3{
  font-size: .8rem;
}

.sub-text-2{
  font-size: .9rem;
}

.sub-text-1{
  font-size: 1rem;
}

.text-lgray{
  color: #939393;
}

.text-red{
  color: #7d0c0c;
}

.text-blue{
  color: #0d31d3;
}

.text-green{
  color: #029415;
}

.styless{
  background-color: transparent;
}

.synth-placeholder{
  font-size: 1.06rem;
  color: #00000082;
  text-align: justify;
}


/* MODAL */
.filter-modal{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 17, 17, 0.307);
  display: flex;
  justify-content: center;
}

.filter-modal > div{
  background-color: rgb(255, 255, 255);
  padding: 1rem 3rem;
  padding-bottom: 2rem;
  border-radius: .3rem;
  position: relative;
  overflow: hidden;
  max-width: 50%;
  max-height: 80%;
  height: max-content;
  min-height: 40%;
}

@media only screen and (max-width: 768px) {
  .filter-modal > div{
    max-width: 98%;
    padding: 1rem 1rem;
  }
}

.filter-modal > div > button{
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
}
/* MODAL END */



/* SELECT FILTER */
.select-filter-display > div > .select-filter-body{
  display: none;
}


.select-filter-display:hover > div > .select-filter-body{
  /* display: block; */
}

.select-filter-title.opened{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #7367f0;
}

.select-filter-body{
  border: 1px solid rgba(0, 0, 0, 0.116);
  border-top: 0;
  border-top-left-radius: 0;
  padding: .4rem;
  padding-top: 0;
  position: absolute;
  background: rgb(249, 249, 249);
}

.select-filter-body > span > div{
  backdrop-filter: blur(2px);
}

ul.select-filter{
  list-style: none;
  margin: 0;
  padding: .5rem;
  border-radius: 1rem;
  z-index: 100;
}

ul.select-filter > li{
  font-size: .9rem;
  padding: .2rem .5rem;
  font-weight: normal;
}

ul.select-filter > li:hover{
  background-color: rgba(85, 28, 231, 0.155);
}
/* SELECT FILTER END*/



.bg-index, .action-td, td.tags{
  border-bottom: 1px solid rgba(17, 17, 17, 0.505) !important;
}

th.del{
  width: 50px;
}

th.del > button{
  border: 1px solid #444;
  border-radius: .23em;
  padding: .4em .6em;
  font-size: 14px;
  background-color: #de3434;
  cursor: pointer;
  transition: ease 250ms background;
  font-weight: bold;
  color: #fff;
}

th.del > button:hover{
  background-color: #fc4848;
}

td.tags > div{
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.tag{
  border-radius: .6rem;
  border: 1px solid #1100676d;
  background-color: #7066cb1a;
  color: #151515;
  width: fit-content;
}

.tag.success{
  border: 1px solid #048021d4;
  color: #075803;
  background-color: #58b75b56;
}

.tag.bridgestone{
  border: 1px solid #000000d4;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
}

.tag.chileneumaticos{
  font-weight: bold;
  border: 1px solid #1d025dd4;
  color: #ececec;
  background-color: rgb(38, 13, 106)
}

.tag.blue{
  border: 1px solid #040480d4;
  color: #080237;
  background-color: #585bb756;
}

.tag.yellow{
  border: 1px solid #b0d214d4;
  color: #2c3402;
  background-color: #d2de5556;
}

.tag.red{
  border: 1px solid #800404d4;
  color: #580303;
  background-color: #b7585856;
}

.tag.gray{
  border: 1px solid #777b78d4;
  color: #5b5f5b;
  background-color: #adb0ad56;
}

.tag.dark-blue{
  border: 1px solid #110278d4;
  background-color: #0b1563e9;
  color: #ffffffda;
}

.tag.l-blue{
  border: 1px solid #6b61aed4;
  background-color: #96a3e3e9;
  color: #f7f7f7;
}

.overflow-visible{
  overflow: visible;
}


/* CUSTOM SIDEBAR SCROLLBAR */
.custom-scrollbar::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 1rem;
  background: #00000010;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(57, 29, 214, 0.275);
  border-radius: 1rem;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(24, 0, 86, 0.092);
}
/* CUSTOM SIDEBAR SCROLLBAR END*/



/* TABS */
.tabs{
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  z-index: 10;
}

.tabs-item{
  padding: .5rem 1.2rem;
  transition: ease border .2s;
  color: rgba(17, 17, 17, 0.681);
  border-top: 3px solid transparent;
  width: max-content;
}

.tabs-item.active{
  border-top: 3px solid #3f0db5b9;
  font-weight: bold;
}

.tabs-item.active:hover{
  border-top: 3px solid #3f0db5b9;
  font-weight: bold;
  cursor: default;
}

.tabs-item:hover{
  border-top: 3px solid #3f0db531;
}
/* TABS END */



/* CUSTOM TABS SCROLLBAR */
.tabs::-webkit-scrollbar{
  height: .29rem;
  position: absolute;
  top: 10rem;
}

.tabs::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: rgba(57, 29, 214, 0.075);
  border-radius: .4rem;
}

.tabs::-webkit-scrollbar-thumb:hover {
  background-color: rgba(57, 29, 214, 0.429);
}

.tabs::-webkit-scrollbar-track {
  background: #00000010;
}
/* CUSTOM TABS SCROLLBAR END */



/* NAVBAR */
.main-menu .navbar-header{
  height: 6rem;
  width: 100%;
}

.main-menu .navbar-header > ul{
  height: 100%;
}

.logo-item{
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.logo-item > a{
  height: 100%;
  margin: auto 0;
}

.logo-item > a > img{
  height: 100%;
  width: 100%;
}
/* NAVBAR END */

.bg-color-brand{
  background-color: #041c62;
}

tr.h-full > td{
  height: 50px;
}

button{
  border: none;
}

.switch-list{
  background-color: #003ef910;
  padding: .2rem .8rem;
  border-radius: 1rem;
}

textarea.no-resize{
  resize: none;
}

.side-bar-button-a{
  background: #041c62;
}

.underline-text:hover{
  text-decoration: underline;
}

.min-w-mobile{
  min-width: 350px;
}

.ul-normal{
  list-style: none;
}

.pagination-ul{
  display: flex;
  flex-direction: row;
  background-color: #d8d6de;
  width: max-content;
  padding: 0;
  border-radius: .4rem;
  border: 1px solid #0000000c;
  overflow-x: hidden;
}

.pagination-li, .pagination-li-arrow{
  border-left: 1px solid #00000010;
  display: flex;
  min-width: 2em;
  padding: .3em;
  max-width: max-content;
  height: 2.2em;
  justify-content: center;
  align-items: center;
  transition: ease background 300ms ;
  font-size: 1.3em;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.pagination-li-arrow{
  appearance: none;
  cursor: default;
  background-color: #787777c3;
  color: #ffffff66;
}

.pagination-li:hover{
  background-color: #6b85d3;
  color: #ffffff;
}

.active-page{
  background-color: #2b4695;
  color: #ffffff;
}

.main-menu.menu-light .navigation li.nav-item a:hover{
  background-color: #00619217;
  border-radius: 4px;
}

.page-link, .page-item{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.card-functions{
  width: fit-content;
  justify-content: space-evenly;
}

.card-functions > *{
  margin-right: 1em;
}

.card-select{
  margin-right: 2em;
}

.small-card{
  height: calc(100% - 2rem);
}

.small-card-header{
  justify-content: space-between;
  margin-bottom: 1.5em;
}

.small-card-icon{
  height: 50px;
  width: auto;
}

.btn{
  padding: .7rem 1rem;
}

.w-fit{
  width: fit-content;
}

.h-fit{
  height: fit-content;
}

.w-max{
  width: max-content;
}

.flex-content-between{
  justify-content: space-between;
}

.link-btn-card{
  position: absolute;
  bottom: 0;
  right: calc(0px - 5px);
  border-radius: 1em 0 0 0;
}

.card .card-title{
  font-size: 1.6em;
  margin: 0;
  font-weight: bold;
}

.flex-col{
  flex-direction: column;
  width: fit-content;
  align-content: center;
}

.small-card .info > h4{
  font-size: 1em;
  margin-left: .3em;
}

.dropdown-menu-rtl{
  right: 0px;
  margin-top: 5px;
}

.custom-form{
  border: 1px solid #3b3e48ae;
  padding: 2.5rem;
}

.form-title{
  font-size: 1.8rem;
}

.form-title{
  font-size: 1.8rem;
  border-radius: 0.358rem;
  padding: 0.7rem 1rem;
  width: fit-content;
}

.form-title.primary{
  background-color: #7367f0;
  color: #ffffff;
}

.form-title.secondary{
  background-color: #118826;
  color: #ffffff;
}

.spinner-modal{
  background-color: #251c3f8d;
  border-radius: .5rem;
  padding: 2rem;
  width: fit-content;
  margin: auto;
}

.bg-login-img, .blocked-modal{
  display: flex;
  width: 100%; 
  left: 0;
  top: 0;
  height: 100%; 
  width: 100%;
}

.login-modal{
  background-image: linear-gradient(to bottom,rgba(232, 228, 252, 0.99), rgba(244, 244, 244, 0.98),rgba(255, 206, 206, 0.99))
}

.blocked-modal{
  z-index: 10;
  width: 100%; 
  backdrop-filter: blur(1px);
}

.blocked-modal.partial{
  position: fixed;
  z-index: 10;
}

.blocked-modal.full{
  position: fixed;
  z-index: 100000000;
}

.login-component{
  z-index: 100000001;
  flex-wrap: wrap;
  min-width: 350px;
}

.login-logo{
  min-width: 250px;
}

.z-10{
  z-index: 10
}

.z-100{
  z-index: 100
}

.fit-cover{
  object-fit: cover;
}


/* ACCORDION */
.accordion-header > .accordion-button.title.collapsed{
  border: 1px solid rgba(17, 17, 17, 0.218);
  border-bottom: none;
  border-radius: .3rem .3rem 0 0;
}

.accordion-header > .title{
  border: 1px solid rgba(17, 17, 17, 0.218);
  background-color: #0b1563e9;
  color: #ffffffda;
  border-radius: .3rem .3rem;
}

.accordion-body{
  background-color: #ffffff41;
  border: 1px solid rgba(17, 17, 17, 0.218);
  border-radius: 0 0 .3rem .3rem;
}

/* ACCORDION END */




/* ACCORDION LIST-HEADER */

.list-header > div > .accordion-header > .title.collapsed,
.list-header > div > .accordion-header > .title,
.list-header > div > .accordion-collapse > .accordion-body{
  border: none;
}

/* ACCORDION LIST-HEADER END */


.table tbody tr td p.success, .table tbody tr td p.failed{
  border-radius: .4rem;
  width: min-content;
  margin: auto;
}

.table tbody tr td p.success{
  border: 1px solid #4dab5f;
  background-color: #79db8b6e;
  color: #0d621d;
}

.table tbody tr td p.failed{
  border: 1px solid #ac544b;
  background-color: #d87e7e5b;
  color: #a53326;
}

.input-inset-button{
  background-color: #f0f0f0;
  width: fit-content;
  transition: .2s ease background-color;
}

.input-inset-button:hover{
  background-color: #dcdcdc;
}

.table-oc{
  background-color: #ff5a623b;
}

/* FILE UPLOADER */
.thumbInner{
  gap: 5px;
  justify-content: center;
  align-content: center;
}

.thumb-img{
  height: auto;
  max-width: 200px;
  border-radius: .4rem;
  object-fit: cover;
}

.thumb{
  overflow: hidden;
  border-radius: .5rem;
  width: fit-content;
  max-height: 150px;
  box-shadow: 2px 1px 8px 1px rgba(6, 8, 9, 0.246);
}

.file-uploader-container{
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-content: center;
  border-radius: .4rem;
  margin-bottom: 1rem;
  min-height: 50px;
  width: 100%;
  max-height: 300px;
}

.file-uploader-container > div{
  border-radius: .4rem 0 0 .4rem;
  border: 1px solid rgba(17, 17, 17, 0.279);
  transition: .2s ease background-color;
  cursor: pointer;
  background-color: rgba(17, 17, 17, 0.055);
  display: flex;
}

.file-uploader-container > div > p {
  margin: auto;
  width: max-content;
  padding: .5rem;
  /* font-size: 18px; */
}

.thumbsContainer{
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  border-radius: 0 .4rem .4rem 0;
  border: 1px solid rgba(17, 17, 17, 0.279);
  border-left: none;
  width: 100%;
  overflow: auto;
}

.file-uploader-container > div:hover{
  background-color: rgba(21, 48, 158, 0.274);
  color: #111;
}
/* TODO: añadir breakpoints */
/* FILE UPLOADER END */


/* NAME AVATAR */

.name-avatar{
  display: inline-block;
  background-color: #44444471;
  border-radius: 50%;
  border: 1px solid #2a2a2a;
  padding: .4rem;
  width: 2.6rem;
  height: 2.6rem;
}

.name-avatar > div > span{
  color: rgba(17, 17, 17, 0.634);
  margin: auto;
  font-weight: bold;
}

.color-red{
  background-color: #8a111140;
  border: 1px solid #8a1111;
}

.color-blue{
  background-color: #5c42b940;
  border: 1px solid #3d288b;
}

.color-green{
  background-color: #17bd4640;
  border: 1px solid #17bd46;
}

.color-yellow{
  background-color: #b3b31340;
  border: 1px solid #b3b313;
}
.color-orange{
  background-color: #fc993c74;
  border: 1px solid #ac5f16;
}

.color-pink{
  background-color: #aa1a8d40;
  border: 1px solid #aa1a8d;
}

.color-brown{
  background-color: #9a4f0e61;
  border: 1px solid #613611;
}

.color-purple{
  background-color: #72049640;
  border: 1px solid #761c94;
}
/* NAME AVATAR END */


@media (max-width: 900px){
  .dashboard-line{
    flex-wrap: wrap;
  }

}

.table-col-min {
  width: 0px;
  white-space: nowrap;
}



/* Fullscreen image */

.fullscreen-container {
  display: flex;

  width: 100%;

  aspect-ratio: 1 / 1;
}

.holder {
  width: 100%;

  display: none;
}

.fullscreen.open ~ .holder {
  display: flex;
}

.fullscreen {
  display: flex;
}

.fullscreen.open {
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 10000;

  align-items: center;
  justify-content: center;

  backdrop-filter: blur(0.3rem);
  
  background-color: rgba(0, 0, 0, 0.5);
}

.fullscreen-img {
  display: flex;

  position: relative;

  width: 100%;
}

.fullscreen.open > .fullscreen-img {
  width: 40%;
}

.fullscreen-img > img {
  aspect-ratio: 1 / 1;

  width: 100%;

  object-fit: contain;
}

.fullscreen-img > button {
  display: none;
  align-items: center;
  justify-content: center;

  background-color: red;
  color: #FFFFFF;

  border-radius: 50%;

  padding: 0.5rem;

  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
}

.fullscreen.open > .fullscreen-img > button {
  display: flex;
}

/* MARK: DETALLE - SERVICIOS */

.img-grid-table {
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  padding: 1rem;
  gap: 1rem;
}

.img-grid-table > figure {
  display: flex;
  position: relative;
  justify-content: center;
  border: 1px solid #d5e2ff;
  border-radius: .3rem;
  padding: .2rem;
}

.img-grid-table > figure  > .btn-delete {
  position: absolute;
  right: .2rem;
}

/* MARK: PLANILLA-GARANTIAS */

.form-planilla-garantia-evidencia {
  width: 150px;
  padding: 1rem;
  border: 1px solid #777;
  border-radius: 5px;
  position: relative;
  transition: ease border 200ms;
}

.form-planilla-garantia-evidencia[data-selected="1"] {
  border-color: #4629ff;
}
.form-planilla-garantia-evidencia:hover  {
  border-color: #4629ff91;
}

.form-planilla-garantia-evidencia > img {
  width: 100%;
  max-height: 100%;
}