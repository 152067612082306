@import 'modal-solicitud.css';
@import 'modal-devolucion.css';

.linea-bitacora-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.linea-bitacora-btn {
  width: fit-content;
  color: #111;
  font-weight: bold;
  font-size: 1.2rem;
  padding: .2rem .5rem;
  border-radius: .3rem;
  border: 1px solid var(--light-grey);
  transition: opacity ease 200ms;
}
.linea-bitacora-btn:hover {
  opacity: .9;
}

.linea-bitacora-btn:disabled {
  background-color: #ddd;
}