.devolucion-info-section, .devolucion-pedido-info-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
}

.devolucion-info-data, .pedido-info-data  {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: .5rem;
}

.devolucion-pedido-info-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: .4rem;
}