figure.zoom {
    background-position: 50% 50%;
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: zoom-in;
    background-repeat: no-repeat;
}

.img-fluid-zoom:hover {
    opacity: 0;
}
.img-fluid-zoom:touch {
    opacity: 0;
}
.img-fluid-zoom {
    transition: opacity .2s;
    display: block;
    width: 100%;
}