.scrollToTop{
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 9999;
  opacity: .25;
  transition:ease 200ms opacity;
}

.scrollToTop:hover{
  opacity: 1;
}