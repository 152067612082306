.bitacora-devoluciones-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
}

.bitacora-devoluciones-tabs > button {
  background-color: transparent;
  color: var(--main-color);
}
.bitacora-devoluciones-tabs > button:hover {
  text-decoration: underline;
}